





























































































































import { removeDuplicate } from "@/helpers/common";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  AdditionalPrivilege,
  DataMenu,
  RequestAddUsers,
  RequestUpdateUsers,
  ResponseDetailUser,
} from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";
import {
  checkPasswordStrength,
  emailValidator,
} from "@/validator/globalvalidator";
import Vue from "vue";
import type { Row } from "./components/user/TableRole.vue";

type DetailRole = {
  idMenu: string | null;
  description: string | null;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  key: number;
  disabled: true;
};

export default Vue.extend({
  name: "CreateUser",
  mixins: [MNotificationVue],
  components: {
    "table-role": () => import("./components/user/TableRole.vue"),
  },
  data() {
    return {
      deleteAddPrivilegeIds: [] as string[],
      loading: {
        table: false,
        submit: false,
      },
      mode: Mode.CREATE as string,
      idUser: "" as string,
      columnTabPane: ["Additional Privilege"] as string[],
      columnIdTabPane: [] as string[],
      selectedRowKeys: [] as number[],
      activeTabPane: "Additional Privilege" as string,
      dataSourceTabPane: {},
      formRules: {
        employee: {
          label: "lbl_employee",
          name: "employee",
          placeholder: "lbl_name_placeholder",
          decorator: [
            "employee",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        username: {
          label: "lbl_signin_username",
          name: "username",
          placeholder: "lbl_signin_username_placeholder",
          decorator: [
            "username",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        truckingUsername: {
          label: "lbl_trucking_username",
          name: "truckingUsername",
          placeholder: "lbl_trucking_username_placeholder",
          decorator: [
            "truckingUsername",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        password: {
          label: "lbl_signin_password",
          name: "password",
          placeholder: "lbl_signin_password_placeholder",
          decorator: [
            "password",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: checkPasswordStrength,
                },
              ],
            },
          ],
        },
        confirmpassword: {
          label: "lbl_confirm_password",
          name: "confirmpassword",
          placeholder: "lbl_confirm_password_placeholder",
          decorator: [
            "confirmpassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: this.customValidator,
                },
              ],
            },
          ],
        },
        email: {
          label: "lbl_email",
          name: "email",
          placeholder: "lbl_email_placeholder",
          decorator: [
            "email",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: emailValidator,
                },
              ],
            },
          ],
        },
        isActive: {
          label: "lbl_active",
          decorator: [
            "isActive",
            {
              valuePropName: "checked",
              initialValue: true,
            },
          ],
        },
        role: {
          label: "lbl_role",
          name: "role",
          placeholder: "lbl_role_placeholder",
          decorator: [
            "role",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                  type: "array",
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as Row[],
      columnsTable: [
        {
          title: this.$t("lbl_menu"),
          dataIndex: "idMenu",
          key: "idMenu",
          width: 250,
          scopedSlots: { customRender: "selectMenu" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          width: 250,
        },
        {
          title: this.$t("lbl_create"),
          dataIndex: "create",
          key: "create",
          width: 150,
          scopedSlots: { customRender: "checkbox" },
          align: "center",
        },
        {
          title: this.$t("lbl_read"),
          dataIndex: "read",
          key: "read",
          width: 150,
          scopedSlots: { customRender: "checkbox" },
          align: "center",
        },
        {
          title: this.$t("lbl_edit"),
          dataIndex: "update",
          key: "update",
          width: 150,
          scopedSlots: { customRender: "checkbox" },
          align: "center",
        },
        {
          title: this.$t("lbl_delete"),
          dataIndex: "delete",
          key: "delete",
          width: 150,
          scopedSlots: { customRender: "checkbox" },
          align: "center",
        },
      ],
      detailUser: {} as ResponseDetailUser,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
      };
    },
  },
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "addUserForm" });
  },
  created() {
    // set Mode
    this.mode = this.$route.meta.mode;
    this.idUser = this.$route.params.id ?? "";
  },
  mounted() {
    this.initData();
  },
  methods: {
    customValidator(_rule, value, callback) {
      if (this.form.getFieldValue("password") !== value) {
        callback(this.$t("lbl_password_doesnt_match").toString());
      } else {
        callback();
      }
    },
    onSelectRow(selectedRowKeys: number[]) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleCheckBoxTable(value: boolean, key: number, col: string) {
      this.dataSource[key][`${col}`] = value;
      this.dataSource = this.dataSource.slice();
      this.dataSourceTabPane[this.activeTabPane] = this.dataSource.slice();
    },
    setAutoFill(value: { meta: DataMenu }, key: number) {
      const { meta } = value;
      // set description, create, read, update, delete
      this.dataSource[key] = {
        ...this.dataSource[key],
        description: meta.description ?? "",
        create: !!meta.create,
        read: !!meta.read,
        update: !!meta.update,
        delete: !!meta.delete,
      };
      // agar membuat new array
      this.dataSource = this.dataSource.slice();
      this.dataSourceTabPane[this.activeTabPane] = this.dataSource.slice();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onChangeRole(value: string[]): void {
      if (value.length < 1) {
        this.dataSourceTabPane = {
          "Additional Privilege":
            this.dataSourceTabPane["Additional Privilege"],
        };
      }

      const id: string[] = [];
      const name = value.map(data => {
        const splited = data.split("@");
        id.push(splited[0]);
        return splited[1];
      });

      if (name.length > 0) {
        Object.keys(this.dataSourceTabPane).forEach(keyDataSource => {
          if (
            !name.includes(keyDataSource) &&
            keyDataSource !== "Additional Privilege"
          ) {
            delete this.dataSourceTabPane[keyDataSource];
          }
        });
      }

      this.columnIdTabPane = id;
      this.columnTabPane = [
        ...name,
        this.columnTabPane[this.columnTabPane.length - 1],
      ];

      this.columnTabPane.forEach((data, index) => {
        if (this.dataSourceTabPane[data] === undefined) {
          this.loading.table = true;
          userServices
            .detailRole(this.columnIdTabPane[index])
            .then(response => {
              const newArr: DetailRole[] = [];
              response.menus.forEach((menu, i) => {
                newArr.push({
                  idMenu: menu.id,
                  description: menu.description,
                  create: menu.create,
                  read: menu.read,
                  update: menu.update,
                  delete: menu.delete,
                  key: i,
                  disabled: true,
                });
              });
              this.$set(this.dataSourceTabPane, data, newArr);
            })
            .finally(() => (this.loading.table = false));
        }
      });
    },
    onTabChange(key) {
      this.activeTabPane = key;
      this.dataSource = this.dataSourceTabPane[key].slice();
    },
    submitForm(e: Event): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) {
          this.showNotifError("lbl_validation_required_error");
          return;
        }

        const listMenuId: string[] = [];
        const checkDataHaveIdNoCrud: boolean[] = [];
        const additionalPrivileges: AdditionalPrivilege[] = [];

        const hasMenu = this.dataSourceTabPane["Additional Privilege"].filter(
          data => data.idMenu
        );

        hasMenu.forEach(data => {
          listMenuId.push(data.idMenu);
          // check have idMenu && no CRUD
          if (!data.create && !data.read && !data.update && !data.delete) {
            checkDataHaveIdNoCrud.push(true);
          }
          additionalPrivileges.push({
            idMenu: data.idMenu,
            create: data.create ? data.createId : null, // deleted || null
            read: data.read ? data.readId : null,
            update: data.update ? data.updateId : null,
            delete: data.delete ? data.deleteId : null,
            id: data.id,
          });
        });

        if (checkDataHaveIdNoCrud.includes(true)) {
          this.showNotifError("notif_required_select_privilege");
          return;
        }

        const checkDuplicateData = listMenuId.some((element, index) => {
          return listMenuId.indexOf(element) !== index;
        });

        if (checkDuplicateData) {
          this.showNotifError("notif_duplicate_additional_privilege");
          return;
        }

        // with password
        const payloadCreate = {
          employeeId: values["employee"],
          username: values["username"],
          password: values["password"],
          email: values["email"],
          active: values.isActive,
          truckingUsername: values["truckingUsername"],
          roleIds: this.columnIdTabPane,
          additionalPrivilege: additionalPrivileges,
        };

        // without password
        const payloadUpdate = {
          ...payloadCreate,
          deleteAddPrivilegeIds: this.deleteAddPrivilegeIds,
        };

        this.loading.submit = true;
        if (this.mode === Mode.CREATE) this.createUser(payloadCreate);
        else if (this.mode === Mode.EDIT)
          this.updateUser(this.idUser, payloadUpdate);
      });
    },
    resetConfPassword(): void {
      this.form.setFieldsValue({
        confirmpassword: "",
      });
    },
    initData(): void {
      if (this.mode === Mode.CREATE) {
        this.initAdditionalPrivilege();
      } else {
        this.getDetailUser(this.idUser);
      }
    },
    initAdditionalPrivilege(): void {
      this.dataSourceTabPane = {
        "Additional Privilege": [
          {
            idMenu: null,
            description: null,
            create: false,
            read: false,
            update: false,
            delete: false,
            key: 0,
            disabled: false,
          },
        ],
      };
      this.dataSource = this.dataSourceTabPane[this.activeTabPane];
    },
    getDetailUser(userId: string): void {
      this.loading.table = true;
      userServices
        .detailUser(userId)
        .then((data: ResponseDetailUser) => {
          this.detailUser = data;
          // set additional privileges kalau lengthnya 0
          if (this.detailUser.additionalPrivileges.length < 1) {
            this.initAdditionalPrivilege();
          } else {
            this.dataSourceTabPane = {
              "Additional Privilege": [],
            };
            // set additional privileges kalau lengthnya > 0
            this.detailUser.additionalPrivileges.forEach((item, index) => {
              this.dataSourceTabPane["Additional Privilege"].push({
                idMenu: item.menuId,
                description: item.menuDesc,
                create: !!item.create,
                read: !!item.read,
                update: !!item.update,
                delete: !!item.delete,
                createId: item.create,
                readId: item.read,
                updateId: item.update,
                deleteId: item.delete,
                key: index,
                disabled: false,
                id: item.id || "",
              });
            });
          }

          // variabel untuk set role & dimasukan ke handleSelect
          let joinArrayIdName: string[] = [];
          data.roles.forEach(role => {
            joinArrayIdName = [...joinArrayIdName, `${role.id}@${role.name}`];
          });

          this.form.setFieldsValue({
            email: data.email,
            employee: data.employeeId,
            role: joinArrayIdName,
            username: data.username,
            isActive: data.active,
            truckingUsername: data.truckingUsername,
          });
          this.dataSource = this.dataSourceTabPane[this.activeTabPane];

          // trigger for create related tab based on user roles
          this.onChangeRole(joinArrayIdName);
        })
        .finally(() => (this.loading.table = false));
    },
    onChangeMenu(value: string, index: number): void {
      // set menu dengan id / value
      const { dataSource } = this;
      this.dataSource[index] = { ...dataSource[index], idMenu: value };
    },
    onChangeRoleTable(param: {
      dataSource: Row[];
      deletedIds: string[];
      action: "addRow" | "deleteRow" | "input";
    }): void {
      const { dataSource: newSource, deletedIds } = param;
      const { deleteAddPrivilegeIds } = this;
      this.dataSource = [...newSource];
      this.dataSourceTabPane[this.activeTabPane] = [...this.dataSource];
      this.deleteAddPrivilegeIds = removeDuplicate([
        ...deleteAddPrivilegeIds,
        ...deletedIds,
      ]);
    },
    createUser(payload: RequestAddUsers): void {
      userServices
        .addUser(payload)
        .then(() => {
          this.showNotifSuccess("notif_create_success");
          this.form.resetFields();
          this.$router.push({ name: "user.list" });
        })
        .finally(() => (this.loading.submit = false));
    },
    updateUser(userId: string, payload: RequestUpdateUsers): void {
      userServices
        .updateUser(payload, userId)
        .then(() => {
          this.showNotifSuccess("notif_update_success");
          this.form.resetFields();
          this.$router.push({ name: "user.list" });
        })
        .finally(() => (this.loading.submit = false));
    },
  },
});
