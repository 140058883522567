var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title:
          _vm.mode === "create"
            ? _vm.$t("lbl_create_user")
            : _vm.$t("lbl_update_user")
      }
    },
    [
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form, "data-testid": "createuser-form" } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, lg: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.employee.label) }
                        },
                        [
                          _c("contact-select", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.employee.decorator,
                                expression: "formRules.employee.decorator"
                              }
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formRules.employee.placeholder
                              ),
                              "c-default-value": _vm.detailUser.employeeId,
                              query: "employee~true_AND_active~true"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.username.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.username.decorator,
                                expression: "formRules.username.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.username.name,
                              placeholder: _vm.$t(
                                _vm.formRules.username.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.mode === "create"
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.password.label)
                              }
                            },
                            [
                              _c("a-input-password", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.password.decorator,
                                    expression: "formRules.password.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.password.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.password.placeholder
                                  ),
                                  autocomplete: "off"
                                },
                                on: { change: _vm.resetConfPassword }
                              }),
                              _c(
                                "ul",
                                { staticStyle: { "font-size": "11px" } },
                                [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("lbl_password_rule_1"))
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("lbl_password_rule_2"))
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("lbl_password_rule_3"))
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("lbl_password_rule_4"))
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mode === "create"
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.confirmpassword.label
                                )
                              }
                            },
                            [
                              _c("a-input-password", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.confirmpassword.decorator,
                                    expression:
                                      "formRules.confirmpassword.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.confirmpassword.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.confirmpassword.placeholder
                                  ),
                                  autocomplete: "false"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, lg: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.email.label),
                            hasFeedback: true
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.email.decorator,
                                expression: "formRules.email.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.email.name,
                              placeholder: _vm.$t(
                                _vm.formRules.email.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t(_vm.formRules.role.label) } },
                        [
                          _c("role-select", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.role.decorator,
                                expression: "formRules.role.decorator"
                              }
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formRules.role.placeholder
                              )
                            },
                            on: { change: _vm.onChangeRole }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.isActive.label) }
                        },
                        [
                          _c("a-switch", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.isActive.decorator,
                                expression: "formRules.isActive.decorator"
                              }
                            ]
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.truckingUsername.label)
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.truckingUsername.decorator,
                                expression:
                                  "formRules.truckingUsername.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.truckingUsername.name,
                              placeholder: _vm.$t(
                                _vm.formRules.truckingUsername.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-tabs",
            {
              attrs: {
                "default-active-key": _vm.activeTabPane,
                type: "card",
                "data-testid": "createuser-active-tab"
              },
              on: { change: _vm.onTabChange }
            },
            _vm._l(_vm.columnTabPane, function(item) {
              return _c(
                "a-tab-pane",
                { key: item, staticClass: "py-4", attrs: { tab: item } },
                [
                  _c("table-role", {
                    attrs: {
                      "props-data-source": _vm.dataSource,
                      "active-tab-pane": _vm.activeTabPane
                    },
                    on: { "change:data-source": _vm.onChangeRoleTable }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _vm.$can("create", "user") || _vm.$can("update", "user")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submit,
                            icon: "save",
                            "data-testid": "createuser-btn-submit"
                          },
                          on: { click: _vm.submitForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }